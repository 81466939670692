<template>
  <div>
    <div v-if="report" class="container" style="padding: 0 5px !important">
      <div class="preteinsion__title">Претензия к отзыву</div>
      <form class="preteinsion__form" method="post" enctype="multipart/form-data">
        <div class="preteinsion__warning">
          <span>Внимание!</span> Ваша претензия будет отправлена Администрации сервиса.
        </div>
        <div class="preteinsion__inputs__group flex">
          <div
            class="preteinsion__label fieldValid"
            :class="{ invalid: $v.report.review.$anyError }"
          >
            <span class="preteinsion__input__name">
              <label for="popup__number">Укажите номер отзыва</label>
              <a href="#" class="preteinsion__openPopup"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <circle cx="8" cy="8" r="8" fill="#B4BDC1" />
                  <path
                    d="M7.684 10.229C7.61067 10.229 7.552 10.207 7.508 10.163C7.464 10.119 7.442 10.0603 7.442 9.987V9.536C7.45667 9.14733 7.55567 8.81 7.739 8.524C7.92967 8.238 8.19733 7.919 8.542 7.567C8.806 7.29567 9.00033 7.072 9.125 6.896C9.257 6.72 9.334 6.52933 9.356 6.324C9.36333 6.29467 9.367 6.247 9.367 6.181C9.367 5.84367 9.224 5.576 8.938 5.378C8.652 5.18 8.31833 5.081 7.937 5.081C7.52633 5.081 7.18167 5.191 6.903 5.411C6.63167 5.62367 6.45567 5.961 6.375 6.423C6.34567 6.58433 6.25033 6.665 6.089 6.665H5.605C5.539 6.665 5.48033 6.643 5.429 6.599C5.385 6.555 5.363 6.49633 5.363 6.423C5.37767 6.01967 5.49133 5.64933 5.704 5.312C5.924 4.96733 6.22833 4.696 6.617 4.498C7.013 4.29267 7.464 4.19 7.97 4.19C8.50533 4.19 8.95633 4.28167 9.323 4.465C9.68967 4.64833 9.961 4.89033 10.137 5.191C10.313 5.48433 10.401 5.796 10.401 6.126C10.401 6.5 10.3167 6.82267 10.148 7.094C9.97933 7.36533 9.72633 7.68067 9.389 8.04C9.11033 8.34067 8.90133 8.59733 8.762 8.81C8.62267 9.01533 8.542 9.24633 8.52 9.503C8.50533 9.70833 8.498 9.86233 8.498 9.965C8.46133 10.141 8.36967 10.229 8.223 10.229H7.684ZM7.585 12C7.51167 12 7.44933 11.978 7.398 11.934C7.354 11.8827 7.332 11.8203 7.332 11.747V11.109C7.332 11.0357 7.354 10.9733 7.398 10.922C7.44933 10.8707 7.51167 10.845 7.585 10.845H8.267C8.34033 10.845 8.40267 10.8707 8.454 10.922C8.50533 10.9733 8.531 11.0357 8.531 11.109V11.747C8.531 11.8203 8.50533 11.8827 8.454 11.934C8.40267 11.978 8.34033 12 8.267 12H7.585Z"
                    fill="#192229"
                  /></svg
              ></a>
            </span>
            <input
              v-model="$v.report.review.$model"
              type="number"
              id="popup__number"
              name="review_id"
              class="preteinsion__input"
            />
            <span class="error__text">Заполните это поле, чтобы продолжить</span>
          </div>
          <label
            class="preteinsion__label fieldValid"
            :class="{ invalid: $v.report.email.$anyError }"
          >
            <span class="preteinsion__input__name"> E-mail для связи с вами </span>
            <input
              v-model="$v.report.email.$model"
              type="email"
              name="email"
              style="margin-top: 5.5px"
              class="preteinsion__input"
            />
            <span class="error__text">Заполните это поле, чтобы продолжить</span>
          </label>
          <div
            style="margin-top: 20px"
            class="preteinsion__label fieldValid"
            :class="{ invalid: $v.instaname.$anyError }"
          >
            <span class="preteinsion__input__name">
              <label for="popup__number">Укажите ваш instagram аккаунт из отзыва</label>
            </span>
            <instaname-input
              @blur="getCode"
              style="width: 100% !important"
              type="text"
              id="popup__instagram"
              name="instagram"
              :inpClass="'preteinsion__input'"
              class="preteinsion__input"
              placeholder=""
              v-model="$v.instaname.$model"
            ></instaname-input>

            <span class="error__text">Заполните это поле, чтобы продолжить</span>
          </div>
          <div class="preteinsion__confirm flex">
            <div class="preteinsion__confirm__title">Подтвердите ваш аккаунт</div>
            <div class="preteinsion__confirm__desc">
              Скопируйте код и вставьте в шапку аккаунта instagram, указанного в отзыве. Так вы
              подтвердите, что аккаунт действительно принадлежит вам.
            </div>
            <input
              id="code"
              :value="code ? `https://easyprbot.com/${code}` : null"
              name="code"
              disabled
              readonly
              class="preteinsion__input preteinsion__confirm__input copy"
              style="font-family: inherit"
            />
            <a class="preteinsion__confirm__copy copy-btn" data-clipboard-target="#code">
              Скопировать
            </a>
          </div>
        </div>
        <label
          class="preteinsion__inputs__group long__group fieldValid"
          :class="{ invalid: $v.report.text.$anyError }"
        >
          <span class="preteinsion__input__name"> Текст претензии </span>
          <span class="preteinsion__input__desc">
            Подробно опишите почему вы считаете, что данный отзыв недостоверен или нарушает
            законодательство
          </span>
          <textarea
            v-model="$v.report.text.$model"
            name="text"
            class="preteinsion__textarea"
          ></textarea>
          <span class="error__text">Заполните это поле, чтобы продолжить</span>
        </label>
        <div
          class="preteinsion__inputs__group long__group fieldValid"
          :class="{ invalid: $v.report.reason.$anyError }"
          data-toggle="buttons"
        >
          <div class="preteinsion__input__name">Выберите подходящий вариант</div>
          <div class="preteinsion__radioinputs btn-group flex-column">
            <template v-for="(option, idx) in meta.actions.POST.reason.choices">
              <input
                v-model="$v.report.reason.$model"
                :key="option.value"
                :id="idx"
                type="radio"
                name="reason"
                :value="option.value"
                class="preteinsion__radio__input"
                checked
              />
              <label :key="option.value + 'daW'" :for="idx" class="preteinsion__radio flex">
                <span class="preteinsion__radio__false"></span>
                <span class="preteinsion__radio__text">{{ option.display_name }}</span>
              </label>
            </template>
          </div>
          <span class="error__text">Выберите один вариант, чтобы продолжить</span>
        </div>
        <div
          class="preteinsion__photo__content fieldValid"
          :class="{ invalid: $v.report.file.$anyError }"
        >
          <p class="preteinsion__photo__text">
            Копия официальной претензии (в формате JPEG, PNG или PDF). Претензия должна содержать
            реквизиты заявителя (телефон, адрес, ФИО, email), текст претензии с обоснованиями почему
            отзыв должен быть заблокирован, дату и подпись заявителя.
          </p>
          <p class="preteinsion__photo__text">
            На каждый отзыв должна быть составлена отдельная претензия с указанием ссылки именно на
            этот отзыв. Претензии, в которых указано больше одного отзыва, не обрабатываются
            системой и будут отклонены.
            <a
              class="link"
              target="_blank"
              href="https://easyprbot.com/static/web/Претензия%20образец.pdf"
              >Пример претензии</a
            >
          </p>
          <div class="preteinsion__photo__func flex">
            <a @click="$refs.repFile.click()" class="preteinsion__photo__link"> Загрузить файл </a>
            <div v-if="report.file" class="preteinsion__photo__right">
              <div class="preteinsion__photo__name">{{ report.file.name }}</div>
              <a
                class="fs-12 gray-text"
                style="display: block !important"
                @click="
                  () => {
                    report.file = null;
                  }
                "
                >Удалить</a
              >
            </div>
            <input
              @input="
                (e) => {
                  report.file = e.target.files[0];
                }
              "
              type="file"
              name="file"
              ref="repFile"
              class="preteinsion__photo__input"
              accept="image/*"
            />
            <span class="error__text">Выберите файл, чтобы продолжить</span>
          </div>
        </div>
        <div class="fieldValid" :class="{ invalid: $v.policyAgree.$anyError }">
          <div
            @click="$refs.policyCheck.click()"
            class="option"
            :class="{ 'option--active': policyAgree }"
          >
            <div class="option__select" name="agree"></div>
            <input ref="policyCheck" v-model="$v.policyAgree.$model" type="checkbox" name="agree" />
            <h6 class="option__title">
              Я соглашаюсь с
              <a class="link" href="https://easyprbot.com/privacy">политикой конфиденциальности</a>
            </h6>
          </div>
          <span class="error__text">Необходимо согласиться</span>
        </div>
        <input
          @click.prevent="submit"
          type="submit"
          class="preteinsion__submit"
          value="Отправить претензию"
          id="send-button"
        />
        <p v-if="error" id="e-txt" style="color: red; margin-top: 20px; font-size: 14px">
          {{ error.response.data.detail }}
        </p>
      </form>
      <div v-if="success" style="max-width: 400px" class="mx-auto d-block hovering__pad p-5">
        <div class="d-flex flex-column align-items-center justify-content-between">
          <img src="@main/assets/done_icon.png" alt="" class="form--3__image mb-3" />
          <h2 class="text-center mb-4 mt-1">
            Жалоба принята на модерацию. Ответ придет вам на почту
          </h2>
          <b-button class="ph-42" variant="outline-default" @click="success = false"
            >Оставить еще одну жалобу</b-button
          >
        </div>
      </div>
    </div>
    <loader v-else></loader>
  </div>
</template>

<script>
import reviewsApi from '@main/api/reviews';
import bloggers from '@main/api/bloggers';
import { required, email } from 'vuelidate/lib/validators';
import Loader from '../Loader.vue';
import InstanameInput from '../forms/InstanameInput.vue';

export default {
  data: () => ({
    success: false,
    error: null,
    report: null,
    meta: null,
    instaname: null,
    code: null,
    policyAgree: null,
  }),
  components: {
    Loader,
    InstanameInput,
  },
  async mounted() {
    const model = await reviewsApi.reports.options();
    const entries = new Map(Object.keys(model.actions.POST).map((val) => [val, null]));
    this.report = Object.fromEntries(entries);
    this.meta = model;
  },
  validations: {
    instaname: { required },
    policyAgree: {
      required,
      notfalse: async (val) => {
        return val;
      },
    },
    report: {
      review: { required },
      email: { required, email },
      text: { required },
      reason: { required },
      file: { required },
    },
  },
  methods: {
    async submit() {
      this.$v.$touch();
      console.log(this.report);
      if (this.$v.$anyError) {
        return;
      }
      const form = new FormData();
      Object.keys(this.report).forEach((element) => {
        form.append(element, this.report[element]);
      });
      try {
        await reviewsApi.reports.create(form);
        this.success = true;
      } catch (e) {
        this.error = e;
      }
    },
    async getCode() {
      const codeObj = await bloggers.getInstagramCode(this.instaname);
      this.code = codeObj.code;
    },
  },
};
</script>

<style src="@main/styles/review_report.css" lang="css" scoped></style>
<style lang="scss" scoped>
::v-deep legend {
  font-size: 16px !important;
}
#success-popup .popup {
  margin: auto;
  padding-bottom: 0;
  z-index: 0;
}
#success-popup .container {
  display: flex;
  padding: 30px 28px;
  justify-content: space-around;
  flex-direction: column;
}
#success-popup .form__message {
  text-align: center;
  margin: 20px 0;
}
#success-popup a {
  color: #000000;
  height: 36px;
  line-height: 16px;
  font-size: 14px;
  border: 1px solid #d5dfe4;
  border-radius: 5px;
  padding: 10px;
}
</style>
